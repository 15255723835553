import React from 'react'
import AppAppBar from './components/AppAppBar'
import SignUp from './components/SignUp'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; 

const Waitlist = () => {
    const [mode, setMode] = React.useState('light');
    const defaultTheme = createTheme({ palette: { mode } });
   
    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Box sx={{ bgcolor: 'background.default',
                        pt: { xs: 8, sm: 12 },
                        pb: { xs: 8, sm: 12 }, }}>
                <div>
                    <AppAppBar mode={mode} toggleColorMode={toggleColorMode}/>
                </div>
                <div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100dvw',
                    position: 'fixed',
                }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center">
                <Grid item> 
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSftSJpGNgBgqSE4CwpRl039gMuIhz9wx1XckVGPfO-tHrYTCg/viewform?embedded=true" width="640" height="876" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    {/* <SignUp customerType={'Creator'}/> */}
                </Grid>
                {/* <Grid item>
                    <SignUp customerType={'Player'}/>
                </Grid> */}
                </Grid>
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default Waitlist;