import logo from './logo.svg';
import LandingPage from './material-ui-landingpage/LandingPage'
import Waitlist from './material-ui-landingpage/Waitlist'
import {HashRouter, Route, Routes} from "react-router-dom"

import './App.css';

function App() {
  return (
    <HashRouter>
        <Routes>
            <Route path="/waitlist" element={<Waitlist/>}/>
            <Route path="/" element={<LandingPage/>}/>
        </Routes>
    </HashRouter>
  )
}

export default App
